<template>
  <div class="col-sm-6 col-md-4 col-xl-3">
    <div class="my-4 text-center">
      <b-modal id="extensionModal" ref="extensionModal" size="lg" :title="'Demande de Prolongation '" title-class="font-18" hide-footer>
        <form >
          <div class="row">
            <div class="col-xs-12 col-md-4">
              <label for="formrow-inputCity">Date début d'intervnetion *</label>
              <input
                type="date"
                v-model="extensionRequest.dateStart"
                class="form-control"
                name=""
                id=""
                :class="{'is-invalid': submitted && $v.extensionRequest.dateStart.$error,}" 
              />
            </div>
            <div class="col-xs-12 col-md-4">
              <label for="formrow-inputCity">Date fin d'intervnetion *</label>
              <input
                type="date"
                v-model="extensionRequest.dateEnd"
                class="form-control"
                name=""
                id=""
                :class="{'is-invalid': submitted && $v.extensionRequest.dateEnd.$error,}" 
              />
            </div>
            <div class="col-xs-12 col-md-4">
              <label for="formrow-inputCity">Heure fin d'intervnetion *</label>
              <input
                type="time"
                v-model="extensionRequest.timeEnd"
                class="form-control"
                name=""
                id=""
                :class="{'is-invalid': submitted && $v.extensionRequest.timeEnd.$error,}" 
              />
            </div>
            <div class="form-group col-12">
              <label for="formrow-inputCity">Commentaires *</label>
              <!-- Editor -->
              <ckeditor v-model="extensionRequest.comments" :editor="editor"></ckeditor>
            </div>
          </div>
          <div>
            <button type="button" @click="submit('maintenance_supervisor_approved')" class="btn btn-label btn-primary float-right ml-2" >
              <i class="far fa-save text-white label-icon"></i> Enregistrer 
            </button>
            <button type="button"  @click="cancelBtn" class="btn btn-light btn-label float-right ml-2">
              <i class="fas fa-times-circle text-danger label-icon"></i> Annuler
            </button>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  fetchProductsList,
} from "@/api/common";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { required , requiredIf ,maxLength} from "vuelidate/lib/validators";
export default {
  props: {
      moId: {
          type: Number,
      },
  },
  components: {ckeditor: CKEditor.component},
  data() {
    return {
      show: false,
      products: [],
      extensionRequest: {
        moId: "",
        status: "",
        comments: "",
        dateStart: "",
        dateEnd: "",
        timeEnd: "",
      },
      editor: ClassicEditor,

      executionResponsibleTypes: [
        {
          id: 'company',
          title: 'SONATRACH',
        },

        {
          id: 'supplier',
          title: 'Prestataire'
        }
      ],
      supId : null,
      inventionProductsList : [],
      fields: [{ id: 1 }],
      phoneData: [{ id: 1 }],
      submitted: false,
    };
  },
  validations: {
    extensionRequest : {
      dateStart      : { required },
      dateEnd        : { required },
      timeEnd        : { required,},
      comments       : { required },
    },
  },
  mounted: function () { 
      // this.fetchProductsList();
    // this.$root.$on('showAssignEmployeesModal', () => { // here you need to use the arrow function
     
    // })
  },
  watch : {
  },
  methods: {
    cancelBtn(){
      this.$refs['extensionModal'].hide()
    },
    submit() {
      this.submitted = true
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.submitted = false
        this.extensionRequest.moId   = this.$props.moId;
        this.$http
        .post("/ops/maintenance_orders/extensionRequest", this.extensionRequest)

        .then((res) => {
          var status = res.data.original.status;
          switch (status) {
            case 200:
              this.$toast.success("l'operation a ete effectuée avec succes");
              this.$refs['extensionModal'].hide()
              this.$emit('realoadSingleMaintenanceOrderData',true)
              break;

            case 500:
              this.$toast.error(res.data.original.msg);
              
              break;
          }
        })
        .catch(() => {
          
        })
        .finally(() => {});
      }
    },
  },
};
</script>
